import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import tw, { styled } from 'twin.macro'

import { MainLayout, NewMainLayout } from '@common/layouts'
import { SEO } from '@components'

export const query = graphql`
  query QuestionQuery($id: Int) {
    question(strapiId: { eq: $id }) {
      title
      slug
      createdAt: created_at(formatString: "MMMM D, YYYY")
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      tags {
        id
        name
        slug
      }
      childMarkdownRemark {
        excerpt(pruneLength: 120)
        html
        timeToRead
      }
    }
    defaultImage: file(relativePath: { eq: "question-default-image.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Wrapper = styled.div`
  ${tw`flex flex-col mx-auto max-w-screen-md items-center py-16 px-6`}
  ${tw`md:(px-8)`}
`

const MainImage = styled(Img)`
  ${tw`w-full mx-auto mb-12`}
`

const Title = styled.h1`
  ${tw`text-3xl font-bold tracking-tight leading-10 mb-10`}
  ${tw`md:(text-5xl tracking-tighter leading-18)`}
`

const Content = styled.section`
  ${tw`prose prose max-w-none text-justify leading-6 antialiased`}
`

const TagList = styled.ul`
  ${tw`flex flex-wrap items-center my-12 text-gray-700`}
`

const TagListItem = styled.li`
  ${tw`flex items-center justify-center text-xs antialiased tracking-tight h-5 px-1 mr-1 mb-2 border rounded uppercase font-semibold`}
`

const QuestionDetail = ({ data: { question, defaultImage }, pageContext }) => {
  const Layout = pageContext.country === 'US' ? NewMainLayout : MainLayout

  const questionImage = question.image
    ? question.image.childImageSharp.fluid
    : defaultImage.childImageSharp.fluid

  return (
    <Layout>
      <SEO
        title={question.title}
        description={question.childMarkdownRemark.excerpt}
        image={questionImage}
      />

      <Wrapper>
        <article tw="w-full" itemScope itemType="http://schema.org/Article">
          <header>
            <MainImage fluid={questionImage} itemProp="image" />
            <Title itemProp="headline">{question.title}</Title>
          </header>

          <Content
            itemProp="articleBody"
            dangerouslySetInnerHTML={{
              __html: question.childMarkdownRemark.html,
            }}
          />
          <TagList>
            {question.tags.map((tag) => (
              <TagListItem key={tag.id}>
                <Link title={tag.name} to={`/question-tag/${tag.slug}`}>
                  {tag.name}
                </Link>
              </TagListItem>
            ))}
          </TagList>
        </article>
      </Wrapper>
    </Layout>
  )
}

export default QuestionDetail
